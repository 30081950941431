import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { of } from 'rxjs';
import {AuthService} from '../services/auth.service';
import {tap, take, map} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> {

if (!!this.authService.isUserLoggedIn()) {
    // do nothing
    return of(true);
} else {
    this.router.navigate(['/pages/login']);
    return of(false);
}
        //
        // return this.authService.isLoggedIn().authInfo$.pipe(
        //     map(authInfo => authInfo.isLoggedIn()),
        //     take(1),
        //     tap(allowed => {
        //         if (!allowed) {
        //             this.router.navigate(['/pages/login']);
        //         }
        //     }), );
    }
}

import {Routes} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {AuthGuard} from './guards/auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'posts/list',
        pathMatch: 'full',
    }, {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './dashboard/dashboard.module#DashboardModule',
                canActivate: [AuthGuard]
            }, {
                path: 'components',
                loadChildren: './components/components.module#ComponentsModule'
            }, {
                path: 'forms',
                loadChildren: './forms/forms.module#Forms'
            }, {
                path: 'tables',
                loadChildren: './tables/tables.module#TablesModule'
            }, {
                path: 'admin',
                loadChildren: './admin/admin.module#AdminModule',
                canActivate: [AuthGuard]
            }, {
                path: 'posts',
                loadChildren: './posts/posts.module#PostsModule',
                canActivate: [AuthGuard]
            }, {
                path: 'surveys',
                loadChildren: './surveys/surveys.module#SurveysModule',
                canActivate: [AuthGuard]
            }, {
                path: 'adverts',
                loadChildren: './adverts/adverts.module#AdvertsModule',
                canActivate: [AuthGuard]
            }, {
                path: 'widgets',
                loadChildren: './widgets/widgets.module#WidgetsModule'
            }, {
                path: 'calendar',
                loadChildren: './calendar/calendar.module#CalendarModule'
            }, {
                path: '',
                loadChildren: './userpage/user.module#UserModule'
            }, {
                path: '',
                loadChildren: './timeline/timeline.module#TimelineModule'
            }
        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    }
];
